<template>
  <div class="Setting-first">
    <!--导航栏--->
    <div class="Setting-first-one"> <van-nav-bar title="小店设置" left-arrow @click-left="$router.back(-1)" /></div>

    <div class="setting-center">
      <div style="font-size:16px;color:#333333;background-color: #fff;align-self: center;">小店头像</div>
      <van-image
        round
        width="4.3rem"
        height="4.3rem"
        :src="myshoppic.imgUrl"
      />
    </div>
    <van-divider :style="{margin: '0'}"/>

    <div class="setting-center">
      <span style="color: #333333;font-size: 17px;">店铺名称</span>
      <span style="color: rgba(0,0,0,0.5);font-size: 17px;">{{myshoppic.name}}的小店</span>
    </div>
    <van-divider :style="{margin: '0'}"/>

    <div>
      <p style="font-size:17px; padding:16px 0 0 16px">小店公告</p>
      <van-field
        v-model="myshoppic.detail"
        rows="4"
        autosize
        type="textarea"
        maxlength="100"
        placeholder="请输入店铺公告描述（最多一百字）"
        show-word-limit
      />
    </div>

    <!-- 底部 -->
    <div @click="Settings">
      <van-goods-action>
        <van-goods-action-button color="linear-gradient(to right, #FF7200, #FF3C00)" type="primary" text="保存" />
      </van-goods-action>
    </div>
  </div>
</template>

<script>
import { settingPost } from "@/api/settinger/Settinger";
import { getMyShoppic } from "@/api/MyShop/MyShop";
import { Toast } from 'vant';
export default {
  name: "Settinger",
  data() {
    return {
      myshoppic: {
        detail: ''
      }
    };
  },
  mounted() {},
  created() {
    this.getMyShoppictype();
  },
  methods: {
    getMyShoppictype(){
      getMyShoppic().then(res =>{
        if(res.data.code == 0){
          console.log(res)
          this.myshoppic = res.data.data
        }
        else{
          Toast(res.data.msg)
        }
      })
    },
    Settings() {
      settingPost({detail: this.myshoppic.detail}).then((res) => {
        if(res.data.code == 0){
          Toast('保存成功')
          this.$router.back(-1);
        }
        else{
          Toast(res.data.msg)
        }
      }).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.Setting-first {
  background-color: #fff;
}
.Setting-first-one{
.van-nav-bar{
  font-weight: bold;
}
}

.setting {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  .setting-one {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 24px 0 0 10px;
    font-size: 17px;
    font-weight: bold;
    // width: 50%;
  }
  .setting-two {
    padding: 24px 0 0 10px;
  }
}

.setting-center {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  text-align: center;

}
.setting-foot {
  .van-field {
    font-size: 17px;
    color: #000;
  }
}
.setting-foot-save {
  height: 50px;
  // border: 1px solid red;
  background: url("../../static/会员升级_slices/矩形.png") no-repeat center
    center;
  margin-top: 10px;
  p {
    //  display: flex;
    //  justify-content: center;
    //  align-items: center;
    text-align: center;
    padding-top: 10px;
    color: #fff;
  }
}
</style>
